<template>
<div>

    <form @submit="onSubmit">
      <div style="display:none;overflow:hidden;height:0;">
          <input type="text" class="txt">
          <div>
            <label for="rate">Rate</label><input type="range" min="0.5" max="2" value="1" step="0.1" id="rate">
            <div class="rate-value">1</div>
            <div class="clearfix"></div>
          </div>
          <div>
            <label for="pitch">Pitch</label><input type="range" min="0" max="2" value="1.1" step="0.1" id="pitch">
            <div class="pitch-value">1</div>
            <div class="clearfix"></div>
          </div>
          <select>

          </select>
          <div class="controls">
            <button id="play" type="submit">Play</button>
          </div>
      </div>
    </form>

</div>

</template>

<script>

    export default {
        name: 'TextToSpeech',
        computed: {

        },
        data () {
            return {
                synth: null,
                inputForm: null,
                inputText: null,
                voiceSelect: null,
                pitch: null,
                pitchValue: null,
                rate: null,
                rateValue: null,
                voices: [],
                paused: false,
                lastvalue: null,
                lasteventname: null,
                lastlanguage: null
            }
        },

        methods: {
            onSubmit: function(e){
                e.preventDefault();
                this.speak();
                this.inputTxt.blur();
            },
            speak: function(value, eventname, language){

              console.log("START SPEAK Function")
              //if(this.inputTxt.value !== ''){

              //if(value == '') {value = 'Hallo Begrüßung'}
              if(value == '') {value = ''}
              this.lastvalue = value;
              this.lasteventname = eventname;
              this.lastlanguage = language;

              if(value !== ''){

                //this.buildSynth();

                var utterThis = new SpeechSynthesisUtterance(value);
                var selectedOption = this.voiceSelect.selectedOptions[0].getAttribute('data-name');

                console.log("VOICES");
                console.log(this.voices);

                for(var i = 0; i < this.voices.length ; i++) {
                  if(this.voices[i].name === "Anna" && language == "de") {
                    utterThis.voice = this.voices[i];
                    console.log(selectedOption)
                  } else if (this.voices[i].name === "Samantha" && language == "en") {
                    utterThis.voice = this.voices[i];
                    console.log(selectedOption)
                  }
                }

                utterThis.pitch = this.pitch.value;                
                utterThis.rate = .8;
                //console.log("rate value: " + this.rate.value)
                var that = this;

                //utterThis.volume = 0.5;

                utterThis.onpause = function(event) {
                  console.log('Speech paused after ' + event.elapsedTime + ' milliseconds.');
                };
                utterThis.onend = function() {
                  console.log('READY with Speech');                                                      
                  if(!that.paused) {
                    console.log("send finishedSpeech");
                    that.$emit('finishedSpeech', eventname);
                  }
                };                                
                this.synth.speak(utterThis);
              }
            },
            cancel: function(){

                console.log("hier cancel funktion!")
                this.synth.cancel(); // utterance1 stops being spoken immediately, and both are removed from the queue

            },
            pause: function(){

                console.log("hier PAUSE funktion!")
                this.paused = true;
                this.synth.pause();

            },
            resume: function(){

                console.log("hier RESUME funktion!")
                this.paused = false;
                //this.synth.resume();
                this.speak(this.lastvalue, this.lasteventname, this.lastlanguage);

            },
            populateVoiceList: function() {

              this.voices = this.synth.getVoices();
              var selectedIndex = this.voiceSelect.selectedIndex < 0 ? 0 : this.voiceSelect.selectedIndex;
              this.voiceSelect.innerHTML = '';
              for(var i = 0; i < this.voices.length ; i++) {
                var option = document.createElement('option');
                option.textContent = this.voices[i].name + ' (' + this.voices[i].lang + ')';

                if(this.voices[i].default) {
                  option.textContent += ' -- DEFAULT';
                }

                option.setAttribute('data-lang', this.voices[i].lang);
                option.setAttribute('data-name', this.voices[i].name);
                this.voiceSelect.appendChild(option);
              }
              this.voiceSelect.selectedIndex = selectedIndex;
            },
            buildSynth: function() {
                console.log("build new synth");
                this.synth = window.speechSynthesis;

                this.inputForm = document.querySelector('form');
                this.inputTxt = document.querySelector('.txt');
                this.voiceSelect = document.querySelector('select');

                this.pitch = document.querySelector('#pitch');
                this.pitchValue = document.querySelector('.pitch-value');
                this.rate = document.querySelector('#rate');
                this.rateValue = document.querySelector('.rate-value');

                this.populateVoiceList();

                if (speechSynthesis.onvoiceschanged !== undefined) {
                  speechSynthesis.onvoiceschanged = this.populateVoiceList;
                }

                this.pitch.onchange = function() {
                  this.pitchValue.textContent = this.pitch.value;
                }

                this.rate.onchange = function() {
                  this.rateValue.textContent = this.rate.value;
                }

                this.voiceSelect.onchange = function(){
                  this.speak();
                }

            }            

        },
        mounted () {

            this.synth = window.speechSynthesis;

            this.inputForm = document.querySelector('form');
            this.inputTxt = document.querySelector('.txt');
            this.voiceSelect = document.querySelector('select');

            this.pitch = document.querySelector('#pitch');
            this.pitchValue = document.querySelector('.pitch-value');
            this.rate = document.querySelector('#rate');
            this.rateValue = document.querySelector('.rate-value');

            this.populateVoiceList();

            if (speechSynthesis.onvoiceschanged !== undefined) {
              speechSynthesis.onvoiceschanged = this.populateVoiceList;
            }

            this.pitch.onchange = function() {
              this.pitchValue.textContent = this.pitch.value;
            }

            this.rate.onchange = function() {
              this.rateValue.textContent = this.rate.value;
            }

            this.voiceSelect.onchange = function(){
              this.speak();
            }

        },
        beforeDestroy () {
            //this.audio.removeEventListener('timeupdate', this._handlePlayingUI)
        }

    }



</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>


body, html {
  margin: 0;
}

html {
  height: 100%;
}

body {
  height: 90%;
  max-width: 800px;
  margin: 0 auto;
}

h1, p {
  font-family: sans-serif;
  text-align: center;
  padding: 20px;
}

.txt, select, form > div {
  display: block;
  margin: 0 auto;
  font-family: sans-serif;
  font-size: 16px;
  padding: 5px;
}

.txt {
  width: 80%;
}

select {
  width: 83%;
}

form > div {
  width: 81%;
}

.txt, form > div {
  margin-bottom: 10px;
  overflow: auto;
}

.clearfix {
  clear: both;
}

label {
  float: left;
  width: 10%;
  line-height: 1.5;
}

input {
  border:2px solid red;
}

.rate-value, .pitch-value {
  float: right;
  width: 5%;
  line-height: 1.5;
}

#rate, #pitch {
  float: right;
  width: 81%;
}

.controls {
  text-align: center;
  margin-top: 10px;
}

.controls button {
  padding: 10px;
}

</style>
