import Vue from 'vue';
import VueRouter from 'vue-router'
import App from './App.vue';
import "bootstrap";
import {library} from "@fortawesome/fontawesome-svg-core";
import "bootstrap/dist/css/bootstrap.css";
import "animate.css/animate.css";
import vuetify from '@/plugins/vuetify';
import VueI18n from 'vue-i18n';
import messages from './lang';
import Vue2Editor from 'vue2-editor';
import Toasted from 'vue-toasted';
//import AudioRecorder from 'vue-audio-recorder';

// tmp deactive
//import VueRecord from '@codekraft-studio/vue-record';
import VueWaveSurfer from 'vue-wave-surfer';
import VModal from 'vue-js-modal';
import VueFilterDateFormat from '@vuejs-community/vue-filter-date-format';
import VueFilterDateParse from '@vuejs-community/vue-filter-date-parse';
import VueLazyload from 'vue-lazyload';
import axios from 'axios';
import VueAxios from 'vue-axios';
import VueCookies from 'vue-cookies';

import store from './helper/store.js';

import {
  faBrain,
  faBoxes
} from "@fortawesome/free-solid-svg-icons";

import './assets/css/tagsinput.css';
import './assets/css/style.css';

import NAOstage from "./components/Stage.vue";
//import Chatbot from "./components/Chatbot.vue";
import NAO from "./components/NAO.vue";


library.add(faBrain, faBoxes);


Vue.prototype.$host = 'default';
//Vue.prototype.$template = process.env.VUE_APP_TEMPLATE || 'default';

/**
 * 'default': testbed 
 * 'innoxr': vr.innohub
 * 'icampus': icampus web
 */
Vue.prototype.$template = 'default';
Vue.prototype.$panoData = [];
var $home = Vue.prototype.$template === 'icampus' ? "vr/" : "";
Vue.prototype.$home = $home;
var naoserver = process.env.VUE_APP_NAOSERVER || '/naoserver/';
console.log(naoserver);
Vue.prototype.$config = {
    
    imagepath: location.origin + '/files/images/',
    graphpath: 'https://icampus.th-wildau.de/vr/graf/',
    tb_host: process.env.VUE_APP_DSP_API_HOST || location.origin + '/dsp-api/',
};

/**
 * Socket connection
 */
/* */
import socketio from 'socket.io-client';
import VueSocketIO from 'vue-socket.io';

console.log("naoserver:");
console.log(naoserver);

//const SocketInstance = socketio.connect('https://testbeddev.innohub13.de', {   
const SocketInstance = socketio.connect(naoserver, {
    //path: naoserver + "socket.io",
    path: "/socket.io",
    secure: true,
    //secure: false,
    query: {
        token: window.localStorage.getItem('auth')
    }
});

Vue.use(new VueSocketIO({
    debug: true,
    connection: SocketInstance
}));
/* */

Vue.use(VueI18n);
export const i18n = new VueI18n({
  locale: 'de',
  fallbackLocale: 'de',
  messages
});

//Vue.use(abilitiesPlugin, ability);

Vue.use(VueRouter);
Vue.use(Vue2Editor);
Vue.use(VModal);
Vue.use(VueAxios, axios);
Vue.use(VueLazyload);
var toastoptions = {
    position: 'top-center',
    duration: 4000
}
Vue.use(Toasted, toastoptions);
//Vue.use(AudioRecorder);

// tmp deactive
//Vue.use(VueRecord);
Vue.use(VueWaveSurfer);

Vue.use(VueCookies);
Vue.use(VueFilterDateFormat);
Vue.use(VueFilterDateParse);


axios.defaults.withCredentials = true;

//Generate random token:
//const token = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
const token = null;

// set global cookie
Vue.$cookies.config('7d','','',true, 'None');
//Vue.$cookies.set('USERID',token, null, null, 'rosassdev.innohub13.de', true);
Vue.$cookies.set('USERID',token);
//Vue.$cookies.set('USERID',token,'7d','.innohub13.de' );


store.commit('clearUser');


Vue.config.productionTip = false;

const router = new VueRouter({
  mode: 'history',
  routes: [    
    /*
    {
      path: "/"+$home,
      component: Home,
      name: "Home"
    },        
    {
      path: "/"+$home+"virtuell",
      component: Virtuell,
      name: "Virtuell"
    },
    */
    {
      path: "/"+$home,
      component: NAOstage,
      name: "NAOstage"
    },
    {
      path: "/"+$home+"nao",
      component: NAO,
      name: "NAO"
    },    
   /*
    {
      path: "/"+$home+"panorama",
      component: PanoramaFrame,
      name: "PanoramaFrame",
      props: (route) => ({ 
        query: parseInt(route.query.q),
        map: parseInt(route.query.m) 
      })
    },
    {
      path: "/"+$home+"pn/:panoid/:tourid",
      component: PanoramaFrame,
      name: "Panotour",
      props: (route) => ({         
        query: parseInt(route.params.panoid),        
        tour: parseInt(route.params.tourid)
      })
    },    
    {
      path: "/"+$home+"pn/:panoid",
      component: PanoramaFrame,
      name: "Panosingle",
      props: (route) => ({         
        query: parseInt(route.params.panoid)        
      })
    },        
    {
      path: "/"+$home+"panosingle",
      component: Panorama,
      name: "Single"
    },
    */
    /*
    {
      path: "/"+$home+"suche",
      component: Suche,
      name: "Suche",
      props: (route) => ({         
        query: route.query.t
      })
    },
    */
    /**
     * Suche nach Thema
     */
    /*
    {
      path: "/"+$home+"thema/:tagid",
      component: Thema,
      name: "Thema",
      props: (route) => ({         
        query: route.params.tagid
      })
    },
    {
      path: "/"+$home+"login",
      component: Login,
      name: "Login"
    },
    {
      //path: "/"+$home+"nlp",
      path: "/"+$home+"t/nlp-process",
      component: NLP,
      name: "NLP"
    }
    */
  ],
  scrollBehavior() {
        return {x: 0, y: 0}
    }
})

export const eventBus = new Vue();

new Vue({
  vuetify,
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
