<template>
  <div id="stage">          

     <!-- Draggable DIV -->
    <div id="mydiv" v-show='appToggle'>            
      <NaoAppFlex @reloadApp="reloadapp" ref="naoapp">></NaoAppFlex>    
      <v-btn
            class="mx-0 my-0"
            color="#7DCEA0"
            style="float:right;width:28px;"
            @click="$refs.naoapp.reloadframe()"                        
          ><v-icon>mdi-reload</v-icon></v-btn>          
        <div v-if="language == 'de'" class="mydivheader">Hier klicken und bewegen</div>
        <div v-if="language == 'en'" class="mydivheader">Click here and move</div>

    </div> 

    <splitpanes class="default-theme" @resize="paneSize = $event[0].size">
      <pane class="pane1" :size="paneSize">        
        <NAOinfo
          :infocontent="infotext"
          :sizeWidth="paneSize"
          :lang="language"
        ></NAOinfo>
      </pane>
      <pane class="pane2" :size="100 - paneSize">            
        <v-container class="container" v-if="1==1">

          <!-- Stage info -->
          <transition name="show">
            <v-card class="py-2 px-4 pb-4 stage-info" flat v-if="!stageoverlay">
              <h5 style="margin:20px 10px 20px;">Der NAO-Leseroboter im Testbed</h5>
              <!--<v-icon color="green" class="mx-2 my-1 infoicon" size="24">mdi-information</v-icon> -->
              <v-list-item>
              1. Öffne die LeseApp <br>
              </v-list-item>
              <v-btn class="ml-2" color="white" @click='openApp'>App öffnen</v-btn>
              <v-list-item>
              2. Gebe Deinen Namen ein
              </v-list-item>
              <v-list-item>
              3. Wähle die Terminart aus
              </v-list-item>
              <v-list-item>
              4. Entscheide Dich für ein Buch
              </v-list-item>
              <v-list-item>
              5. oder wähle ein Quiz aus
              </v-list-item>
              <v-list-item>
              6. Verwalte alle Bücher und Fragen
              </v-list-item>
              <v-list-item>
              7. Wähle ein Quiz aus
              </v-list-item>

              <h5 style="margin:20px 10px 20px;">NAO-Medienverwaltung</h5>
              <v-btn class="ml-4" color="white" @click='openCms'>CMS Öffnen</v-btn>
              <v-list-item>
              Verwalte alle Bücher und Quizfragen
              </v-list-item>
          </v-card>
          </transition>

          <!-- Stage overlay -->
          <div class="stage-overlay" v-if="stageoverlay"></div>     

          <!-- Controls right column-->
          <v-container class="" fluid id="controls">

            <div id="scroll-target" class="overflow-y-auto">

                <div
                    v-scroll:#scroll-target="onScroll"
                    class="controlsinner"
                  >

                  <div class="uc-title">
                    <img src="../assets/nao-icon.png" class="icon-image" height="50px">
                    <h4 class="mx-2">NAO-Leseroboter</h4>
                  </div>
                  
                  <!-- Audio controls -->
                  <v-row no-gutters>
                      <v-col
                        class="pa-0 ma-0 mb-3 tb-card"
                        cols="12"
                      >

                        <!--<v-progress-linear v-model="percentage" height="5" style="margin-top: 10px; margin-bottom: 10px;" color="white" ></v-progress-linear>-->

                        <div class="pt-2 pl-4" style="text-align:center">

                          <!--
                          <v-btn outlined icon class="ma-2 mr-2" color="white"
                            @click="bewegen"
                            title="Roboter anstupsen"
                            >
                            <v-icon>mdi-gesture-tap</v-icon>
                          </v-btn>
                          -->

                            <v-btn outlined icon class="ma-2 mr-2" color="white" 
                              @click="reloadapp" 
                              title="Reset"
                              >
                                <v-icon>mdi-cached</v-icon>
                            </v-btn>                        
                          
                          <div :class="[audioModus ? 'audiovisible' : 'audioblur']">
                                            
                            <v-btn large outlined icon class="ma-2" color="white" @click.native="playing ? pauseAnswer() : resumeAnswer()">
                                <v-icon size="30" v-if="!playing || paused">mdi-play</v-icon>
                                <v-icon size="30" v-else>mdi-pause</v-icon>
                            </v-btn>
                            
                            <!--
                            <v-btn outlined icon class="ma-2 mr-2" color="white" @click="cancelAnswer" style="">
                                <v-icon>mdi-stop</v-icon>
                            </v-btn>                        

                            <v-btn large outlined icon class="ma-2" color="white" @click="mute()">
                                <v-icon size="30" v-if="!isMuted">mdi-volume-high</v-icon>
                                <v-icon size="30" v-else>mdi-volume-mute</v-icon>
                            </v-btn>
                            -->

                          </div>                      
                        </div>

                        <!--
                        <v-layout justify-center>
                          <v-switch v-model="audioModus" class="mx-2 switch-center" dense color="white" label="webspeech"></v-switch>
                        </v-layout>
                        -->

                      </v-col>
                  </v-row>

                  <TextToSpeech ref="speechAnswer" @finishedSpeech="finishedspeech"></TextToSpeech>

                  <!--<AudioEmbed :file="file" :autoPlay="true" ref="playerRef" @finished="finished"></AudioEmbed>-->

                  <!-- Help & progress -->
                  <TalkBox :answer="currentProcess"></TalkBox>

                  <ShowBox :answer="answer"></ShowBox>

                  <HelpBox :help="help"></HelpBox>

                  <div class="bottomnav">
                    <!--
                    <v-btn @click="visible = !visible" class="mx-0"
                    >Verstecken</v-btn> 
                    -->

                    <!--
                    <NaoApp @reloadApp="reloadapp" ref="naoapp"></NaoApp>
                    -->
                    <div>
                    <v-btn
                      v-on="on"
                      class="appbtn"
                      ref="openBtn"
                      id="naoapp"
                      @click='appToggle = !appToggle; checkSize()'
                      depressed
                    >
                    <img src="../assets/nao-icon.png" class="icon-image">
                    <span>LeseApp</span>
                    </v-btn>
                    </div>
                  
                    <NaoCMS ref="naocms"></NaoCMS>

                  </div>

                  <!--
                  <v-card class="py-2 px-4 pb-4">
                      <v-icon color="green" class="mx-2 my-1 infoicon" size="24">mdi-cog</v-icon>
                      <h4>NAO-Medienverwaltung</h4>
                      <v-btn class="ml-4" color="white" @click='openCms'>Öffnen</v-btn>
                      <v-list-item>
                      Verwalte alle Bücher und Quizfragen
                      </v-list-item>
                  </v-card>
                  -->

                  <v-row no-gutters class="lang">
                    <div v-if="language == 'de'"><img src="https://img.icons8.com/offices/24/000000/germany.png"/></div>
                    <div v-if="language == 'en'"><img src="https://img.icons8.com/offices/30/000000/great-britain.png"/></div>
                  </v-row>

                  <!--
                  <v-row no-gutters class="lang">
                  <v-col
                    class="pl-8 mb-3"
                    cols="12"
                  >

                    <v-container justify-center>
                      <v-radio-group row v-model="radioGroup" class="radiogroup">
                      <v-radio
                        v-for="n in lang"
                        :key="n.id"
                        :label="n.title"
                        :value="n.index"
                        color="white" class="mt-1"
                      ></v-radio>
                      </v-radio-group>
                    </v-container>

                  </v-col>

                  </v-row>
                  -->

                  <Connector ref="connectorRef" @sethelp="setHelp" @setlang="setLang" @setdisplay="setDisplay" @setanswer="setAnswer" @play="playAudio"></Connector>

                  <div class="buttons">
                      <!--
                      <v-btn
                      class="mr-2"
                      @click='play("/audio/goodbye-de-de.mp3")'
                      >
                      <v-icon large>mdi-play</v-icon>
                      </v-btn>

                      <v-btn
                      class="ml-2"
                      @click='play("/audio/received-badge-1-de-de.mp3")'
                      >
                      <v-icon large>mdi-play</v-icon>
                      </v-btn>
                      -->

                  </div>

                  <!--<StageSettings></StageSettings>-->

                </div>

              </div>

          </v-container>
        
        </v-container>
        <NAO ref="nao"></NAO>
      </pane>
    </splitpanes>

  </div>
</template>

<script>

import { Splitpanes, Pane } from 'splitpanes';
import 'splitpanes/dist/splitpanes.css';

import Connector from '../components/Connector';
import NAO from '../components/NAO';
import NAOinfo from './info/info_nao.vue';
//import NaoApp from '../components/monitor/NaoApp';
import NaoAppFlex from '../components/monitor/NaoAppFlex';
import NaoCMS from '../components/monitor/NaoCMS';
import TalkBox from '../components/monitor/TalkBox';
import ShowBox from '../components/monitor/ShowBox';
import HelpBox from '../components/monitor/HelpBox';
//import AudioEmbed from '../components/content/AudioEmbed';
import TextToSpeech from '../components/content/TextToSpeech';
//import StageSettings from '../components/controls/StageSettings';
//import Loading from 'vue-loading-overlay';

export default {
  name: 'Stage',

  components: {
    Splitpanes,
    Pane,
    Connector,
    NAO,
    NAOinfo,
    //NaoApp,
    NaoAppFlex,
    NaoCMS,
    TalkBox,
    ShowBox,
    HelpBox,
    //Loading,
    //AudioEmbed,
    TextToSpeech,
    //StageSettings
  },

  data: function () {
    return {
      appToggle: false,
      visible: true,
      paneSize: 22,
      isLoading: true,
      stageoverlay: false,
      maximum: 99,
      isMuted: false,
      playing: false,
      paused: false,
      loaded: false,
      percentage: 0,
      cart: [],
      file: 'xx.mp3',
      motive: '',
      answer: ' ',
      help: '<span style="color:#999"> </span>',
      radioGroup: 1,
      currentProcess: '',
      currentProcessRaw: '',
      audioAnswer: '',
      audioModus: false,
      modus: 1,
      lang: [
        { title: 'DE', index: 1, link: '' },
        { title: 'EN', index: 2, link: 'Stage' }
      ],
      langEN: [
        'Greeting','FirstMeeting', 'Alphabet', 'Reading', 'PositiveFeedback', 'ReceivedBadge', 'QuizQuestion', 'QuizAnswers','QuizRight', 'QuizWrong', 'Goodbye'
      ],
      langENlive: [
        'Greeting','First meeting', 'Alphabet', 'Reading', 'Positive feedback', 'Received badge', 'Quiz question', 'Quiz answers','QuizRight', 'QuizWrong', 'Goodbye'
      ],
      langDE: [
        'Begrüßung','Erstes Treffen', 'Alphabet', 'Lesen', 'Rückmeldung', 'Abzeichen erhalten', 'Quizfrage', 'Quizantwort','Frage richtig beantwortet', 'Frage falsch beantwortet', 'Verabschiedung'
      ],
      animations: [
        5,2,7,7,5,6,7,7,8,2,4
      ],
      /**
       * 0: bored
       * 1: chill
       * 2: confused
       * 3: curious
       * 4: disco
       * 5: excited
       * 6: happy
       * 7: speak
       * 8: thinking
       */
      language: 'de',
      options: {
        plugins: [
          {
            name: 'url',
            options: {
              attributes: {}, // a hash of attributes to be added in the url
              escape: false   // whether on not to escape special characters in url
            }
          }],
      },
      infotext: null,
      anim: 0
    }
  },
  methods:{

    checkSize () {

      this.paneSize = this.appToggle ? 2 : 22;      

    },

    bewegen () {
      this.$refs.nao.move(1,1,1,this.anim);
    },

    mute () {
        this.isMuted = !this.isMuted
    },    
    playAudio (audiofile) {

      if(this.audioModus == false) {
        this.file = audiofile.file;
        this.motive = audiofile.motive;
        this.$refs.playerRef.play();
      }
    },    
    finished (audiofile) {
      console.log(audiofile);
      this.$refs.connectorRef.sendFinished(this.motive);
    },
    finishedspeech (eventname) {
      console.log("Speech finished!")
      console.log("eventname:" + eventname)
      //console.log("motive: " + eventname)
      this.audioModus = false;
      this.playing = false;

      this.$refs.connectorRef.sendFinished(eventname);
    },
    setHelp (data) {
      //console.log("setHelp gestartet!: Antwort auf Stage erhalten: " + data);
      this.help = data;
    },
    setDisplay (data) {
      //console.log("setDisplay: Antwort auf Stage erhalten: " + data);
      this.answer = data;
    },
    setLang (data) {
      //console.log("setLang:");
      //console.log(data);
      this.language = data;
    },
    setAnswer (data, eventname, language) {
      console.log("setAnswer: Antwort auf Stage erhalten: " + data);            
      this.currentProcess = this.translate(eventname, language);
      this.currentProcessRaw = eventname;
      this.audioModus = true;
      //if(this.audioModus == true) {
        //console.log("audiomodus = true")
        this.playing = true;
        this.$refs.speechAnswer.speak(data,eventname, language);
      //}
    },
    translate (value, lang) {
        if (this.langEN.indexOf(value) != -1) {
          this.anim = this.langEN.indexOf(value);
          if(lang == 'de') {
            return this.langDE[this.langEN.indexOf(value)];
          } else {
            return this.langENlive[this.langEN.indexOf(value)];
          }                    
        } else if (this.langDE.indexOf(value) != -1) {
          this.anim = this.langEN.indexOf(value);
          return this.langDE[this.langDE.indexOf(value)];
        } else {
          return value;
        }
    },
    cancelAnswer () {
      //console.log("CANCEL ANSWER");
      if(this.audioModus == true) {
        this.$refs.speechAnswer.cancel();
      }
    },
    pauseAnswer () {
      //console.log("PAUSE ANSWER - process raw:");
      //console.log(this.currentProcessRaw);      
      this.playing = false;
      this.$refs.speechAnswer.pause();      
    },
    resumeAnswer () {
      //console.log("RESUME ANSWER - process raw:");
      //console.log(this.currentProcessRaw);
      //console.log("Neu connecten ? mit: " + this.currentProcessRaw);
      
      //this.$refs.connectorRef.sendFinished(this.currentProcessRaw);
      if(this.audioModus == true) {
        this.playing = true;
        //this.answer = ' ';
        this.$refs.speechAnswer.resume();
      }
    },
    onScroll () {
        console.log("scrolling");
    },
    reloadapp () {
      this.resumeAnswer();
      this.currentProcess = '';
      this.answer = '';
    },
    openApp () {
      //this.$refs.naoapp.openApp();        
      //console.log("hier klick open");
      //this.$refs.openBtn.$el.click();      
    },
    openCms () {
      this.$refs.naocms.openApp();
    },

    dragElement(elmnt) {
      var pos1 = 0, pos2 = 0, pos3 = 0, pos4 = 0;
      if (document.getElementById(elmnt.id + "header")) {
        // if present, the header is where you move the DIV from:
        document.getElementById(elmnt.id + "header").onmousedown = dragMouseDown;
      } else {
        // otherwise, move the DIV from anywhere inside the DIV:
        elmnt.onmousedown = dragMouseDown;
      }

      function dragMouseDown(e) {
        e = e || window.event;
        e.preventDefault();
        // get the mouse cursor position at startup:
        pos3 = e.clientX;
        pos4 = e.clientY;
        document.onmouseup = closeDragElement;
        // call a function whenever the cursor moves:
        document.onmousemove = elementDrag;
      }

      function elementDrag(e) {
        e = e || window.event;
        e.preventDefault();
        // calculate the new cursor position:
        pos1 = pos3 - e.clientX;
        pos2 = pos4 - e.clientY;
        pos3 = e.clientX;
        pos4 = e.clientY;
        // set the element's new position:
        elmnt.style.top = (elmnt.offsetTop - pos2) + "px";
        elmnt.style.left = (elmnt.offsetLeft - pos1) + "px";
      }

      function closeDragElement() {
        // stop moving when mouse button is released:
        document.onmouseup = null;
        document.onmousemove = null;
      }
    }

  },
  mounted() {    
    this.$watch(
        () => {
            return this.$refs.naoapp.sheet
        },
      (val) => {
        
        this.stageoverlay = !this.stageoverlay;
        console.log('App $watch $refs.naoapp.sheet: ' + val);
        if(!this.stageoverlay) {
          this.help = "Die App kann jederzeit mit dem aktuellen Stand wieder geöffnet werden."
        } else {
          this.help = " ";
        }
      }
    );
    //this.$modal.show('example');  
    this.dragElement(document.getElementById("mydiv"));

  },
  created() {
    setTimeout(() => {
      this.isLoading = false;  
    },2000)
  },
    beforeRouteLeave (to, from , next) {
    const answer = window.confirm('Wollen Sie die Anwendung wirklich verlassen?')    
    if (answer) {
      next()
    } else {
      next(false)
    }    
  }  

};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
.vld-overlay {
  z-index:4 !important;
}
#stage {
  padding:0;
  height:100%;
  padding-top:2px;
  position:static;
  border:0px solid green;
  /*
  background:url('../assets/naoleseroboter.jpg') no-repeat center center;
  background-size:cover ;
  */
}
#stage.container {
  max-width: 2000px;
}
.stage-info {
  width:55%;
  left:5%;
  margin-top:5%;
  display:none;
}
.show-enter-active, .show-leave-active {
  transition: opacity .5s
}
.show-enter, .show-leave-to {
  opacity: 0
}
#controls {
  position:fixed;
  top:0;
  right:0;
  width:30%;
  height:100%;
  padding-top:0px;
  z-index: 1;
  background-color:rgba(45,89,134,0.96);
}
.stage-overlay {
  position: absolute;
  top:0;
  left:0;
  width:70%;
  height:100%;
  /*background-color: rgba(255, 153, 0, 0.8);*/
  /*background-color: rgba(92,175,254,0.8);*/
}
#scroll-target {
  padding-top:60px;
  padding-bottom:80px;
  height:100%;
}
#stage #controls {
  font-size:0.8rem;
}
.uc-nav {  
  margin:0;
  padding:0;    
}
.uc-title {
  padding: 0 10px 10px;  
}
.uc-title img {
  display: block;
  float:left;
  margin:10px 30px 10px 20px;
}
.uc-title h4 {
  margin:0px;
  padding: 20px;
  color:white;
}
.uc-title::after{
  content:"";
  display: block;
  clear:both;
  height:0;
}
.row.no-gutters {
  padding-top:0 !important;
}
.controlsinner {
  overflow:hidden;
}
#connector {
  display:none;
}
#footer {
  height:90px;
  background-color: rgba(255,255,255,0);
}
.radiogroup {
  margin:0;
}
.v-label.theme--light {
  padding-top:0.2rem;
  color:#ffffff;
}
.v-list-item {
  padding:0 10px;
}
.switch-center {
  display: flex;
  justify-content: center;
}
.buttons {
  position:absolute;
  left:80px;
  bottom:1%;
}
.transparent {
  background-color: transparent;
  color:#ffffff;
}
.controlleft {
  box-shadow:none !important;
  height:auto;
  background-color:transparent !important;
  padding-right:10%;
}
.lang {
  position:fixed;
  right:2rem;
  bottom:160px;
  display:none;
}
.drawer {
  margin-top:20%;
  width:60%;
}
.infoicon {
  margin-top:-10px;
}
.cwhite {
  color:#ffffff !important; 
}
.audiovisible {
  opacity:1;
  pointer-events:all;
}
.audioblur {
  opacity:0.4;
  pointer-events: none;
}

/* modal */

.modalx .vm--overlay {
  display:none;
}
.modalx .vm--modal {
  pointer-events: auto;
  padding-top:30px;
  background-color: lightblue;
}
.modalx.hide .vm--modal {
  display: none;
}
.modalx.visible .vm--modal {
  display: block;
}

/* Test */

#mydiv {
  position: absolute;
  z-index: 9;
  background-color: #f1f1f1;
  border: 1px solid #d3d3d3;
  text-align: center;
  width:860px;
  height:505px;
  opacity:0.94;
}
.mydivheader {
  padding: 6px 10px;
  cursor: move;
  z-index: 10;
  background-color: #325981;
  color: #fff;
}

</style>
