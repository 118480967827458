<template>
  <div class="wrap usecaseinfo" :style="'width:'+sizeWidth+'%'">

      <v-row>
        <v-col cols="12" sm="12" class="timelinefirst">
          <div class="timestephead">
            <div class="triangle-down"></div>                  
            <h6 v-if="lang == 'de'" style="margin-top:6px;">Der NAO-Leseroboter im Testbed</h6>
            <h6 v-if="lang == 'en'" style="margin-top:6px;">The NAO Reading Robot in the Testbed</h6>          
          </div>          
        </v-col>
      </v-row>
                                                    
      <v-row>
        <v-col cols="12" sm="12" class="timeline" >                      
            <div class="timestep">
              <div v-if="lang == 'de'" class="ptitle">1. Öffne die LeseApp</div>
              <div v-if="lang == 'en'" class="ptitle">1. Open the Reading App</div>
            </div>
            <div v-if="lang == 'de'" class="stepcontent">
              Die LeseApp öffnet sich in einem separaten Fenster. Das Fenster kann jederzeit geschlossen und wieder geöffnet werden.
              <br>              
            </div>
            <div v-if="lang == 'en'" class="stepcontent">
              The Reading App opens in a separate window. The window can be closed and reopened at any time.
              <br>              
            </div>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" sm="12" class="timeline" >
            <div class="timestep">
              <div v-if="lang == 'de'" class="ptitle">2. Gebe Deinen Namen ein</div> 
              <div v-if="lang == 'en'" class="ptitle">2. Enter your name</div>                    
            </div>
            <div v-if="lang == 'de'" class="stepcontent">
              NAO wird Dich mit Deinem Namen ansprechen.
              <br>              
            </div>            
            <div v-if="lang == 'en'" class="stepcontent">
              NAO will address you by name.
              <br>              
            </div>            
        </v-col>
      </v-row>  

      <v-row>
        <v-col cols="12" sm="12" class="timeline" >
            <div class="timestep">
              <div v-if="lang == 'de'" class="ptitle">3. Wähle die Terminart aus</div>                  
              <div v-if="lang == 'en'" class="ptitle">3. Select the type of appointment</div>                  
            </div>
            <div v-if="lang == 'de'" class="stepcontent">
              Du kannst wählen zwischen Vorlesen, Quiz und Alphabet. Ein Quiz stellt Fragen zu einem auszuwählenden Buch. In der Überung Alphabet werden Fragen zu Buchstaben gestellt.
              <br>
            </div>
            <div v-if="lang == 'en'" class="stepcontent">
              You can choose between read aloud, quiz and alphabet. A quiz asks questions about a book to be selected. In the Alphabet exercise, questions are asked about letters.
              <br>
            </div>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" sm="12" class="timeline" >
            <div class="timestep">
              <div v-if="lang == 'de'" class="ptitle">4. Entscheide Dich für ein Buch</div>                  
              <div v-if="lang == 'en'" class="ptitle">4. Choose a book</div>                  
            </div>
            <div v-if="lang == 'de'" class="stepcontent">
              In der Auswahl findest Du unterschiedliche Titel.
              <br>
            </div>
            <div v-if="lang == 'en'" class="stepcontent">
              In the selection you will find different titles.
              <br>
            </div>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" sm="12" class="timeline" >
            <div class="timestep">
              <div v-if="lang == 'de'" class="ptitle">5. oder wähle ein Quiz aus</div>                  
              <div v-if="lang == 'en'" class="ptitle">5. or choose a quiz</div>                  
            </div>
            <div v-if="lang == 'de'" class="stepcontent">
              Die Fragen in einem Quiz beziehen sich immer auf ein bestimmtes Buch, das vorher auszuwählen ist.
              <br>
            </div>
            <div v-if="lang == 'en'" class="stepcontent">
              The questions in a quiz always refer to a specific book, which has to be selected before.
              <br>
            </div>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" sm="12" class="timeline" >
            <div class="timestep">
              <div v-if="lang == 'de'" class="ptitle">6. Verwalte alle Bücher und Fragen</div>        
              <div v-if="lang == 'en'" class="ptitle">6. Manage all books and questions</div>                  
            </div>
            <div v-if="lang == 'de'" class="stepcontent">
              Über den Knopf CMS erhältst Du einen Einblick in der Verwaltung der Inhalte dieser Anwendung.
            <br>
            </div>
            <div v-if="lang == 'de'" class="stepcontent">
              Click on the button CMS to get an insight into the administration of the contents of this application.
            <br>
            </div>            
        </v-col>
      </v-row>

      <HelpDialog ref="help" :content="helpcontent" class="mr-4"></HelpDialog>

  </div>
</template>

<script>

  import HelpDialog from '../monitor/HelpDialog';  

  export default {
    name: 'info_chatbot',

    components: {
      HelpDialog
    },
    data () {
      return {
        helpcontent: null
      }
    },
    props: {
      infocontent: {
          type: String
      },
      sizeWidth: {
          type: Number
      },
      lang: {
          type: String
      } 
    },    
    methods:{         
      help(name) {        
        this.helpcontent = name;        
        this.$refs.help.openhelp();
      }
    },
    mounted() {        
        //console.log("on mounted");            
    } 
  }
</script>

<style scoped>
.wrap {
  position:fixed;
  top:0;
  bottom:0;
  padding-top:48px;  
  overflow-y: scroll;  
  background-color: rgba(45,89,134,0.66);
}

.wrap .row {
  margin-top:0;
  margin-left:0;
  margin-right:0;
}

.timeline,
.timelinefirst {
  background: linear-gradient( 90deg, transparent, transparent 14px, #ffc08d 14px, #ffc08d 22px, transparent 22px);
  padding:12px 12px 12px 36px;
  font-size:.88rem;
}
.timelinefirst {
  padding:12px 12px 12px 36px;
  font-size:.88rem;
}
.timestep,
.timestephead {
  margin:0;
  padding:10px 0;
  position:sticky;  
  top:0;
  background-color: rgba(111,140,170,0.96);  
  border-bottom:1px solid #ccc;
}
.triangle-down {
  position:absolute;
  left:-29px;
  top:6px;
  width: 0;
  height: 0;
  border-left: 11px solid transparent;
  border-right: 11px solid transparent;
  border-top: 22px solid #FFD2AF;  
}
.timestep::before {
  content: " ";
  width:22px;
  height:22px;
  background-color: #ffc08d;
  border-radius: 14px;
  display:inline-block;
  margin-right:10px;
  margin-left:-29px;
}
.timestep .ptitle {
  font-size:1rem;
  color:white;
  font-weight: bold;
  display:block;
  margin-left:6px;
  margin-top:-28px;
}
.stepcontent {
  padding:10px 16px 10px 6px;
}
.timestephead h6 {
  font-size:1rem;
  color:white;
  font-weight: bold;
  display:block;
  margin-left:8px;
  margin-top:-28px;
}

</style>
